

import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import html2canvas from 'html2canvas';
import { Button } from "react-bootstrap";
import { MdOutlineFileDownload } from "react-icons/md";

const LineChart = ({ lineData, hw }) => {

  
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({position: 'relative',radius:"50px"});

  const svgRef = useRef();


  const handleDownload = () => {
    const svgNode = svgRef.current;

    // Create a canvas element to render the SVG content
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const svgString = new XMLSerializer().serializeToString(svgNode);

    // Set canvas background color to white
    canvas.width = svgNode.clientWidth;
    canvas.height = svgNode.clientHeight;
    ctx.fillStyle = '#ffffff'; // white color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Create an image from the SVG content
    const img = new Image();
    img.onload = () => {
      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas to a data URL
      const imageURL = canvas.toDataURL();
      // Trigger download
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'Sentiment_Over_Time.png';
      link.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
  };

  const margin = { top: 20, right: 20, bottom: 40, left: 40 };
  const width = Math.round(hw.width / 5) - margin.left - margin.right;
  const height = hw.width<1400?Math.round(hw.width/6)- margin.top - margin.bottom :Math.round(hw.width/6.3) - margin.top - margin.bottom;


  useEffect(() => {

    function formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
    }

    // Initialize an object to store sentiment counts for each month
    const sentimentCounts = {};

    // Iterate over each news item to count sentiments for each month
    lineData.forEach(item => {
   if (item.analysis!=null) {
       const monthYear = formatDate(item.publishedAt);
       const sentiment = item.analysis.toLowerCase();
 
       if (!sentimentCounts[monthYear]) {
         sentimentCounts[monthYear] = { negative: 0, neutral: 0, positive: 0 };
       }
 
       sentimentCounts[monthYear][sentiment]++;
   }
    });

    // Convert the sentiment counts object into the desired array format
    const data = Object.entries(sentimentCounts).map(([label, counts]) => ({
      label,
      ...counts,
    }));


    const svg = d3.select(svgRef.current);

    // Clear existing SVG content
    svg.selectAll("*").remove();

    // Append new SVG content
    const svgContainer = svg
      .append("svg")
      .attr("width", width + margin.left + margin.right+35)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, width])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) => Math.max(d.negative, d.neutral, d.positive)) + 10,
      ])
      .nice()
      .range([height, 10]);

    // Draw lines
    const line = d3
      .line()
      .x((d) => x(d.label) + x.bandwidth() / 2)
      .y((d) => y(d.value))
      .curve(d3.curveMonotoneX);

    ["negative", "neutral", "positive"].forEach((key) => {
      svgContainer
        .append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", (d) => {
          if (key === "negative") return "#ff3300";
          if (key === "neutral") return "rgba(250, 192, 19, 0.8)";
          if (key === "positive") return "#2db300";
        })
        .attr("stroke-width", 2)
        .attr("d", (d) =>
          line(
            d.map((datum) => ({
              label: datum.label,
              value: datum[key],
            }))
          )
        );
    });

    // Draw axes
    svgContainer
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));
    svgContainer.append("g").call(d3.axisLeft(y));

    // Add legend
    const legend = svgContainer
      .append("g")
      .attr("transform", `translate(${width - margin.right},${margin.top})`);

    ["positive", "negative","neutral"].forEach((key, i) => {
      const label = legend
        .append("g")
        .attr("transform", `translate(0, ${i * 16})`);
      label
        .append("rect")
        .attr("x", hw.width<1400?6:4)
        .attr("y",  hw.width<1400?"20%":hw.width>1700?"50%":"42%")
        .attr("width", hw.width<1400?10:12)
        .attr("height", hw.width<1400?10:12)
        .attr("fill", (d) => {
          if (key === "negative") return "#ff3300";
          if (key === "neutral") return "rgba(250, 192, 19, 0.8)";
          if (key === "positive") return "#2db300";
        });
      label
        .append("text")
        .attr("x", 18) //-8
        .attr("y",   hw.width<1100?"23.5%":hw.width<1400?"22.5%":hw.width>1700?"52%":"44.5%") //86
        .attr("dy", "0.35em")
        .style("font-size", hw.width<1400?"12px":"14px")
        .text(key.charAt(0).toUpperCase() + key.slice(1));
    });
  }, [lineData, hw]);


  
  return (
    <div>


    <div
      style={backgroundStyle}
      onMouseEnter={() => {
        setShowDownloadButton(true)
        setBackgroundStyle({position: 'relative',backgroundColor:"#f0efef" })
      }}
      onMouseLeave={() => {
        setShowDownloadButton(false)
        setBackgroundStyle({position: 'relative',radius:"50px"})
      }}

      >
    <svg
      width={width + margin.left + margin.right+35} // Set width and height here
      height={height + margin.top + margin.bottom}
      ref={svgRef}
      style={backgroundStyle}
    >
      <g
        transform={`translate(${hw.width / 12},${hw.width / 12})`}
      >
      </g>
    </svg>


      {/* Button to trigger download */}
      {showDownloadButton && (
        <button
          className="btn"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor:"#626252"
          }}
          onClick={handleDownload}
        >
         <MdOutlineFileDownload style={{color:"white"}}/>
        </button>
      )}
    </div>
    </div>
  );


};

export default LineChart;

