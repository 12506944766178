import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";

// Sidebar component for larger screens
function SidebarLarge({ routes, fn }) {

  const [viewport, setViewport] = useState({ width: window.innerWidth, height: window.innerHeight });
  // Chakra Color Mode
  const sidebarBg = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const menuColor = useColorModeValue("gray.400", "white");
  const [showBox, setShowBox] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  function handleBox() {
    setShowBox(!showBox)
    fn()
  }
  
  useEffect(() => {
       
    const handleResize = () => {
      setViewport({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [viewport]);



  return (
    <>
      {showBox ? <Box
      className="side-box"
        display={{ sm: "none", xl: "block" }}
        w="100%"
        position="fixed"
        minH="100%"
        >
        <Box
          bg={sidebarBg}
          transition="1s linear"
          w= {viewport.width<1300 ? "248px" : viewport.width>1690 ? "300px" : "280px"}
          h="100vh"
          m="0px"
          minH="100%"
          overflowX="hidden"
          boxShadow={shadow}
        >
          <Scrollbars >
          <Content routes={routes} />
        </Scrollbars>
        </Box>
      </Box> : <></>}

      <Flex w="max-content" h="max-content" position="absolute" onClick={handleBox}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          w="25px"
          h="25px"
          mx={showBox ? viewport.width<1300 ? "250" : viewport.width>1690 ? "300" : "290px" : "2"}
          _hover={{ cursor: "pointer" }}
          zIndex="1"
        />
      </Flex>
    </>
  );
}


// Sidebar component for smaller screens with a hamburger menu
function SidebarSmall({ routes }) {
  const sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  const menuColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center" style={{ position: "absolute" }}>
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen} >
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          mx="0"
          me="10px"
          _hover={{ cursor: "pointer" }}
        zIndex="1"
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars>
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// Main Sidebar component that handles responsiveness
function Sidebar({ routes, fn }) {
  return (
    <>
    {window.innerWidth<1100   ?  <SidebarSmall routes={routes} />  :    <SidebarLarge routes={routes} fn={fn} />}   
    </>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
