import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import { Utils } from "index";
import Select from 'react-select'

const EditTopic = (props) => {
  const [show, setShow] = useState(false);
  const [topic, setTopic] = useState(props.data); // State to manage input field value

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [categoriesOptions, setCategoriesOptions] = useState([])

  const [categoryids, setCategoryids] = useState([])

  const [categorystr, SetCategorystr] = useState("Categories")


  useEffect(() => {
    if (categoryids.length == 0) {
      SetCategorystr("Categories")
    } else {

      SetCategorystr(`${categoryids.length} Selected`)
    }

  }, [categoryids]);



  useEffect(() => {

    fetch(Utils.baseurl + 'topic/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "id": props.data.id
      }),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        let data = []
        if (result.data.length) {
          result.data.map(item => {
            data.push(item.categoryId)
          })

        }
        setCategoryids(data);
      })
      .catch((error) => console.error(error));



    fetch(Utils.baseurl + 'get/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        categoryName: "",
        status: ""
      }),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        const newArray = result.map(item => ({
          value: item.id,
          label: item.title
        }));

        setCategoriesOptions(newArray);
      })
      .catch((error) => console.error(error));


  }, [props.data.id]);


  // Function to handle input field change
  const handleInputChange = (e) => {
    setTopic(e.target.value);
  };



  const handleCategoryChange = (value) => {
    const categoryId = parseInt(value);

    if (categoryids.includes(categoryId)) {
      // If the category is already selected, remove it
      setCategoryids(prevCategoryIds => prevCategoryIds.filter(id => id !== categoryId));
    } else {
      // If the category is not selected, add it
      setCategoryids(prevCategoryIds => [...prevCategoryIds, categoryId]);
    }
  };

  const categoryCheckboxOption = ({ children, value }) => (
    <div>
      <input
        type="checkbox"
        value={value}
        onChange={() => handleCategoryChange(value)}
        checked={categoryids.includes(parseInt(value))}
        style={{ marginRight: "5px", marginLeft: "5px" }}
      />
      <label>{children}</label>
    </div>
  );




  // Function to handle saving changes
  const handleSaveChanges = async () => {

    let data = { topic: typeof (topic) == 'string' ? topic.trim() : topic.topic.trim(), topicId: props.data.id, categoryId: categoryids }


    if (categoryids.length && data.topic.length) {
      const response = await fetch(Utils.baseurl + 'update/topic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      props.renderFun()
      handleClose(); // Close the modal after saving changes

    } else {
      alert("Please Enter Topic and select atleast one Category")
    }

  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: '38px',
      // Add any other inline styles here
    }),
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
    }
  };


  return (
    <>
      <Button style={{ backgroundColor: "white", display: "flex", borderColor: "lightGray", color: "black", alignItems: "center" }} onClick={handleShow}>
        <FaPencilAlt style={{ marginRight: 10, marginLeft: 10 }} />Edit
      </Button>

      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input field for editing title */}
          <div className="d-flex" style={{ marginBottom: "10px", fontSize: "large" }}>
            <div style={{ marginLeft: "10px" }}><b>Topic</b></div>
            <div style={{ marginLeft: "200px" }}><b>Category</b></div>
          </div>

          <div className="d-flex" style={{ justifyContent: "space-between" }}>

            <Form.Control
              type="text"
              defaultValue={props.data.topic}
              onChange={handleInputChange}
              style={{ fontSize: "20px", width: "50%" }}
            />
            <div style={{ width: "45%", marginLeft: "2px" }}>
              <Select
                options={categoriesOptions}
                isMulti
                closeMenuOnSelect={false}
                components={{ Option: categoryCheckboxOption }}
                placeholder={categorystr}
                styles={customStyles}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#484848", borderColor: "#484848" }} onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTopic;
