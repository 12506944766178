import Table from "react-bootstrap/Table";
import AnalysisModel from "./AnalysisModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { FcInfo } from "react-icons/fc";
import "../index.css";
import { Utils } from "index";
import { useEffect, useState } from "react";
import { index } from "d3";



function StripedColumnsExample({ data, hw, parentfn, loading }) {

  const [apiData, setApiData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isNewsAsc, setIsNewsAsc] = useState(true)
  const [isCatAsc, setIsCatAsc] = useState(true)
  const [isSentAsc, setIsSentAsc] = useState(true)
  const [modalShow, setModalShow] = useState(false);


  data.forEach((article) => {
    const date = new Date(article.publishedAt);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    article.publishedAt = formattedDate;
  });

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const handleNewsSort = async () => {

    setIsNewsAsc(!isNewsAsc)
    let newData = data
    if (isNewsAsc) {
      newData.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }


  const handleCategorySort = async () => {

    setIsCatAsc(!isCatAsc)

    let newData = data
    if (isCatAsc) {
      newData.sort((a, b) => {
        const titleA = a.categoryName.toLowerCase();
        const titleB = b.categoryName.toLowerCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.categoryName.toLowerCase();
        const titleB = b.categoryName.toLowerCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }



  const handleSentimentSort = async () => {

    setIsSentAsc(!isSentAsc)

    let newData = data
    if (isSentAsc) {
      newData.sort((a, b) => {

        const titleA = a.analysis != null ? a.analysis.toLowerCase() : "a"
        const titleB = b.analysis != null ? b.analysis.toLowerCase() : "a"

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.analysis != null ? a.analysis.toLowerCase() : "a"
        const titleB = b.analysis != null ? b.analysis.toLowerCase() : "a"

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }


  const handleAnalys = async (item) => {


    let NewData = apiData


    NewData[item.index].Analysing = true

    setApiData([...NewData])



    // /topic/analys

    fetch(Utils.baseurl + 'topic/analys', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    }).then((response) => response.json()) // Parse response as JSON
      .then((result) => {


        let reqObj = {
          publishedAt: [],
          categoryids: [],
          sourceids: [],
          topicids: []
        }
        parentfn(reqObj)
      })
      .catch((error) => console.error(error));


  }

  useEffect(() => {
    setApiData(data)
    setIsLoading(loading)

  }, [data, loading]);




  return (
    <div style={{ overflowY: "scroll", height: "100vh", backgroundColor: "white" }}>
      <Table >
        <thead style={{ position: "sticky", top: "0" }}>
          <tr style={{ fontSize: hw.width < 1400 ? "13px" : "15px" }}>
            <th>S.No</th>
            <th className="text-center">NEWS <button onClick={() => handleNewsSort()}> {isNewsAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button> </th>
            <th>CATEGORY <button onClick={() => handleCategorySort()}> {isCatAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button></th>
            <th>SENTIMENT <button onClick={() => handleSentimentSort()}> {isSentAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button> </th>
          </tr>
        </thead>
        <tbody>
          {
            apiData.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  {
                    isLoading ?
                      <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> :
                      <p style={{ fontSize: 20, fontWeight: 500 }} >No data found</p>
                  }
                </td>
              </tr>
            ) :
              apiData.map((data, index) => (
                <tr style={{ fontSize: hw.width < 1400 ? "13px" : "15px" }} key={data.id}>
                  <td>{index + 1}</td>
                  <td className="title-hover">
                    <a href={data.newsUrl} target="_blank">
                      {data.title} ({data.publishedAt})&nbsp; ({data.source})
                    </a>
                  </td>
                  <td >
                    {data.categoryName}
                  </td>
                  <td>
                    {data.analysis == null ? <button className="btn btn-sm" onClick={() => handleAnalys({ id: data.id, index: index })} style={data.Analysing == true ? { backgroundColor: "#b35e21", color: "white", width: "90px" } : { width: "90px", backgroundColor: "#4bb486", color: "white" }}><b>{data.Analysing == true ? "Analysing" : "Analyse"}</b></button> : <a > <div className="d-flex justify-content-center align-items-center" > <div style={data.analysis == "Positive" ? { border: "0.5px solid rgb(233, 233, 233)", borderRadius: "5px", padding: "2px 12px", backgroundColor: "rgba(232, 250, 232, 0.788)", color: "green", marginLeft: "12px" } : data.analysis == "Negative" ? { border: "0.5px solid rgb(233, 233, 233)", borderRadius: "5px", padding: "2px 12px", backgroundColor: "rgba(232, 250, 232, 0.788)", color: "red", marginLeft: "12px" } : { border: "0.5px solid rgb(233, 233, 233)", borderRadius: "5px", padding: "2px 12px", backgroundColor: "rgba(232, 250, 232, 0.788)", color: "darkgoldenrod", marginLeft: "12px" }}> {data.analysis} </div>    <AnalysisModel show={modalShow} onHide={toggleModal} data={data.detail_analysis} /> </div></a>}

                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
}

export default StripedColumnsExample;
