// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-dialog {
    width: 1000px;
    max-width: 1000px; /* Adjust the width as needed */
  }

  .wide-column {
    width: 20%;
  }`, "",{"version":3,"sources":["webpack://./src/views/admin/default/dashboard/model.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB,EAAE,+BAA+B;EACpD;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".custom-modal .modal-dialog {\n    width: 1000px;\n    max-width: 1000px; /* Adjust the width as needed */\n  }\n\n  .wide-column {\n    width: 20%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
