import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { MdOutlineFileDownload } from "react-icons/md";

const HorizontalBarChart = ({ data, hw }) => {
  const svgRef = useRef();
  const [defaultSize, setDefaultSize] = useState(true);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({ position: 'relative', radius: "50px" });




  // Function to calculate sentiment percentages and round off
  function calculateSentiment(newsArray) {
    let positiveCount = newsArray.filter(news => news.analysis === "Positive").length;
    let neutralCount = newsArray.filter(news => news.analysis === "Neutral").length;
    let negativeCount = newsArray.filter(news => news.analysis === "Negative").length;
    let totalCount = positiveCount + neutralCount + negativeCount;
    let positivePercent = Math.round(((positiveCount / totalCount) * 100));
    let neutralPercent = Math.round(((neutralCount / totalCount) * 100));
    // let negativePercent = Math.round(((negativeCount / totalCount) * 100));
    let negativePercent = 100 - (positivePercent + neutralPercent)

    return {
      Positive: isNaN(positivePercent) ? 0 : positivePercent,
      Neutral: isNaN(neutralPercent) ? 0 : neutralPercent,
      Negative: isNaN(negativePercent) ? 0 : negativePercent
    };
  }

  // Initialize an empty array to store the calculated sentiment percentages for each topic
  const dynamicDataArray = [];

  // Iterate over the newsData array to calculate sentiment percentages dynamically
  data.forEach(newsCategory => {
    if (newsCategory.newsDataArray.length) {

      const topic = newsCategory.category;
      const sentiment = calculateSentiment(newsCategory.newsDataArray);

      dynamicDataArray.push({ Topic: topic, ...sentiment });
    }
  });




  const handleDownload = () => {
    const svgNode = svgRef.current;

    // Create a canvas element to render the SVG content
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const svgString = new XMLSerializer().serializeToString(svgNode);

    // Set canvas background color to white
    canvas.width = svgNode.clientWidth;
    canvas.height = svgNode.clientHeight;
    ctx.fillStyle = '#ffffff'; // white color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Create an image from the SVG content
    const img = new Image();
    img.onload = () => {
      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas to a data URL
      const imageURL = canvas.toDataURL();
      // Trigger download
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'Sentiment_Over_Category.png';
      link.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
  };


  const margin = { top: 20, right: 72, bottom: 30, left: 90 };
  const width = Math.round(hw.width / 8) > 190 ? Math.round(hw.width / 8) : 160
  const height = (Math.round(hw.height / 3.4) > 200 ? Math.round(hw.height / 3.4) : 200) - margin.top - margin.bottom;
  useEffect(() => {
    width == 200 ? setDefaultSize(true) : setDefaultSize(false)
    let data = dynamicDataArray





    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();


    if (data.length) {

      const svgContainer = svg
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);



      const Topics = data.map((d) => d.Topic);
      const categories = Object.keys(data[0]).filter((key) => key !== "Topic");

      const stack = d3
        .stack()
        .keys(categories)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);
      const stackedData = stack(data);

      const y = d3.scaleBand().domain(Topics).range([0, height]).padding(data.length < 3 ? 0.3 : -0.06);

      const x = d3
        .scaleLinear()
        .domain([0, 100]) // Assuming percentage values
        .range([5, width - 10]);

      const color = d3
        .scaleOrdinal()
        .domain(categories)
        .range(["#2db300", "rgba(250, 192, 19, 0.8)", "#ff3300"]); // Customize with your colors
      let bend = data.length < 4 ? 20 : 10
      let numbendwidth = data.length < 4 ? 2.4 : 2
      svgContainer
        .selectAll(".bar")
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => color(d.key))
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("y", (d) => y(d.data.Topic))
        .attr("x", (d) => x(d[0])) // Start from the left
        .attr("width", (d) => x(d[1]) - x(d[0])) // Width based on data values
        .attr("height", y.bandwidth() - bend)
        .attr("rx", 0) // Set horizontal radius
        .attr("ry", 0); // Set vertical radius

      svgContainer
        .selectAll(".bar-label-positive")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "bar-label-positive")
        .attr("x", (d) => x(d.Positive) - (x(d.Positive) - x(d.Positive - 10)))
        .attr("y", (d) => y(d.Topic) + y.bandwidth() / numbendwidth - 1)
        .text((d) => d.Positive != 0 ? `${d.Positive == NaN ? 0 : d.Positive}%` : "")
        .attr("dy", "0em") // Adjust vertical alignment as needed
        .attr("fill", "white")
        .style("font-size", width / 16);

      svgContainer
        .selectAll(".bar-label-neutral")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "bar-label-neutral")
        .attr("x", (d) => x(d.Positive) + (x(d.Positive) - x(d.Positive - 6)))
        .attr("y", (d) => y(d.Topic) + y.bandwidth() / numbendwidth - 1)
        .text((d) => d.Neutral != 0 ? `${d.Neutral == NaN ? 0 : d.Neutral}%` : "")
        .attr("dy", "0em") // Adjust vertical alignment as needed
        .attr("fill", "black")
        .style("font-size", width / 16);

      svgContainer
        .selectAll(".bar-label-negative")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "bar-label-negative")
        .attr(
          "x",
          (d) =>
            x(d.Positive) + x(d.Neutral) + (x(d.Positive) - x(d.Positive + 3))
        )
        .attr("y", (d) => y(d.Topic) + y.bandwidth() / numbendwidth - 1)
        .text((d) => d.Negative != 0 ? `${d.Negative == NaN ? 0 : d.Negative}%` : "")
        .attr("dy", "0em") // Adjust vertical alignment as needed
        .attr("fill", "white")
        .style("font-size", width / 16);

      svgContainer
        .append("g")
        .attr("class", "axis")
        .call(d3.axisLeft(y))
        .style("font-size", hw.width < 1400 ? "12px" : "15px")
        .selectAll(".domain, .tick line")
        .remove();

      svgContainer
        .append("g")
        .attr("class", "axis")
        .attr("transform", `translate(0,${height - 8})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(hw.width < 1400 ? 3 : 4)
            .tickFormat((d) => `${d}%`)
        )
        .style("font-size", hw.width < 1400 ? "12px" : "15px")
        .selectAll(".domain, .tick line")
        .remove();
    }
  }, [hw, data]);
  {/*  */ }



  return (
    <div
      style={backgroundStyle}
      onMouseEnter={() => {
        setShowDownloadButton(true)
        setBackgroundStyle({ position: 'relative', backgroundColor: "#f0efef" })
      }}
      onMouseLeave={() => {
        setShowDownloadButton(false)
        setBackgroundStyle({ position: 'relative', radius: "50px" })
      }}

    >
      <svg
        width={width + margin.left + margin.right} // Set width and height here
        height={height + margin.top + margin.bottom}
        ref={svgRef}
        style={{ position: 'relative' }}
      >
        <g
          transform={`translate(${hw.width / 12},${hw.width / 12})`}
        >
        </g>
      </svg>


      {/* Button to trigger download */}
      {showDownloadButton && (
        <button
          className="btn "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor: "#626252"
          }}
          onClick={handleDownload}
        >
          <MdOutlineFileDownload style={{ color: "white" }} />
        </button>
      )}
    </div>
  );
};

export default HorizontalBarChart;
