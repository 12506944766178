import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { FaHome, FaUser, FaSearch, FaPencilAlt, FaEye } from "react-icons/fa";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MdDeleteOutline, MdRadioButtonUnchecked } from "react-icons/md";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import { Table } from "react-bootstrap";
import "./category.css";
import ViewCategory from "./ViewCategory";
import { Utils } from "index";
import EditCategory from "./EditCategoryModal";
import AddCategory from "./addCategory";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [apiData, setApiData] = useState([])
  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [categoryName, setCategoryName] = useState('');
  const [status, setStatus] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [isCatAsc, setIsCatAsc] = useState(true)
  const [switchValue, setSwitchValue] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setModalShow(!modalShow);
  };


  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  async function renderList() {
    setIsLoading(true)
    let data = {
      categoryName: categoryName,
      status: status
    }
    fetch(Utils.baseurl + 'get/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        setApiData(result);
        setIsLoading(false)
      })
      .catch((error) => console.error(error));
    setLoader(false)
  }

  useEffect(() => {
    renderList()
  }, []);


  async function handleStatus(data) {
    data.is_active == 1 ? data.is_active = 0 : data.is_active = 1
    const response = await fetch(Utils.baseurl + 'update/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    renderList()
  }




  // Event handler for the search button click
  const handleSearch = () => {
    renderList()
    // Log the values for testing (you can replace this with your desired functionality)

  };



  const handleCheckboxChange = async () => {
    setLoader(true)
    setSwitchValue(!switchValue);

    let data = { status: switchValue ? 0 : 1 }
    const response = await fetch(Utils.baseurl + 'update/category/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    renderList()
  };


  const handleCategorySort = async () => {

    setIsCatAsc(!isCatAsc)
    let newData = apiData
    if (isCatAsc) {
      newData.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }



  const handleDelete = (item) => {
    const result = window.confirm('Are you sure you want to delete this category? All articles and topics related to this category will be deleted.');
    if (result) {
      fetch(Utils.baseurl + 'delete/category', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          renderList()
        })
        .catch((error) => console.error(error));
    }

  };





  return (
    <div>
      <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ fontSize: "25px", fontWeight: "bold", marginLeft: "10px" }}>Categories  </div>
        <div >
          <button
            onClick={toggleModal}
            className="btn d-flex align-items-center"
          >
            <AddCategory show={modalShow} onHide={toggleModal} data={{}} renderFun={renderList} />


          </button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="category-div1 col-12 col-lg-12 bg-white">
          <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>

            <label className="searchlabel">
              <FaSearch style={{ marginRight: 8 }} />
              Search
            </label>


          </div>

          <div className="category-divt ">
            <div >
              <label htmlFor="category-name">Category Name</label>
              <input
                type="text"
                id="category-name"
                placeholder="Enter Name"
                style={{
                  width: "200px",
                  border: "1px solid #abafb3",
                  borderRadius: "5px",
                  paddingLeft: "12px",
                  fontSize: "16px",
                  height: "35px",
                  fontWeight: "normal",
                  color: "black"
                }}
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
            <div >
              <label htmlFor="status">Status </label>
              <select
                id="status"
                style={{
                  width: "160px",
                  border: "1px solid #abafb3",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontSize: "16px",
                  height: "35px"
                }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="searchbutton" style={{ marginBottom: "8px", marginTop: "2px" }}>
              <button
                className="btn d-flex align-items-center "
                style={{ width: "170px", height: "38px", fontSize: "large", backgroundColor: "#484848", color: "white", borderRadius: "9px" }}
                onClick={handleSearch} // Call handleSearch function on button click
              >
                <FaSearch style={{ color: "white", marginRight: 10, marginLeft: 30 }} />
                <b> Search</b>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <Table className="table w-100 p-0">
            <thead>
              <tr>
                <th className="categoryhead bg-light">S.No</th>
                <th className="categoryhead bg-light">Category <button onClick={() => handleCategorySort()}> {isCatAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button></th>
                <th className="categoryhead bg-light"> <div className="d-flex justify-content-center"> <div>Status</div>   <Form><Form.Check type="switch" id="custom-switch" checked={switchValue} onChange={handleCheckboxChange} /></Form></div></th>

                <th className="categoryhead bg-light">Edit</th>
                <th className="categoryhead bg-light">View</th>
                <th className="categoryhead bg-light">Delete</th>
              </tr>
            </thead>
            <tbody>
              {apiData.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    {
                      isLoading ?
                        <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> :
                        <p style={{ fontSize: 20, fontWeight: 500 }} >No data found</p>
                    }
                  </td>
                </tr>
              ) : apiData.map((item, index) => (
                <tr key={item.id}>
                  <td className="categorybody categorytd1">{index + 1}</td>
                  <td className="categorybody categorytd2">{item.title}</td>
                  <td className="categorybody categorytd3">
                    <div className="statusDiv">
                      {loader ? <FontAwesomeIcon icon={faSpinner} spin size="1x" /> :

                        <Form>   {item.is_active === 1 ? (
                          <Form.Check type="switch" id="custom-switch" checked={true} onClick={() => handleStatus({
                            id: item.id,
                            is_active: item.is_active
                          })} />

                        ) : (
                          <Form.Check type="switch" id="custom-switch" checked={false} onClick={() => handleStatus({
                            id: item.id,
                            is_active: item.is_active
                          })} />

                        )}
                        </Form>
                      }
                    </div>
                  </td>
                  <td className="categorybody categorytd4">
                    <div className="editDiv">
                      <button
                        onClick={toggleModal}
                      >
                      </button>
                      <EditCategory show={modalShow} style={{ backgroundColor: "red" }} onHide={toggleModal} data={{ title: item.title, id: item.id }} renderFun={renderList} />

                    </div>
                  </td>
                  <td className="categorybody categorytd5">
                    <div className="viewDiv">
                      {/* Button to open the modal */}
                      <button
                        onClick={toggleModal}
                      >

                      </button>

                      {/* Render the modal component */}
                      <ViewCategory show={modalShow} onHide={toggleModal} id={item.id} />
                    </div>

                  </td>
                  <td className="topicbody ">
                    <button className="btn" style={{ border: "0px solid gray" }} onClick={() => handleDelete({ id: item.id })} ><MdDeleteOutline size="25px" /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );

}
