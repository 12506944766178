import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineTopic
} from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";

// Admin Imports
import { VscSourceControl } from "react-icons/vsc";
import MainDashboard from "views/admin/default";
import Category from "./views/admin/category";
import topic from "./views/admin/topic";
import source from "./views/admin/source"
import { AiOutlineHome } from "react-icons/ai";
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={AiOutlineHome}width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: " Category",
    layout: "/admin",
    path: "/category",
    icon: <Icon as={BiCategoryAlt} width="20px" height="20px" color="inherit" />,
    component: Category,
  },
  {
    name: " Topic",
    layout: "/admin",
    path: "/topic",
    icon: <Icon as={MdOutlineTopic} width="20px" height="20px" color="inherit" />,
    component: topic,
  },
  {
    name: "News Source",
    layout: "/admin",
    path: "/source",
    icon: <Icon as={VscSourceControl} width="20px" height="20px" color="inherit" />,
    component: source,
  },
];

export default routes;
