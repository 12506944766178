
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { MdOutlineFileDownload } from "react-icons/md";

// Color mapping for analysis types
const analysisColors = {
  Positive: "#2db300",
  Negative: "#ff3300",
  Neutral: "rgba(250, 192, 19, 0.8)"
};

const DonutChart = ({ donutData, hw }) => {
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({ position: 'relative', radius: "50px", marginTop: "5px" });
  const chRef = useRef();

  // Initialize counters for each analysis type
  let neutralCount = 0;
  let negativeCount = 0;
  let positiveCount = 0;

  // Iterate through the articles and count occurrences of each analysis type
  donutData.forEach(article => {
    switch (article.analysis) {
      case 'Neutral':
        neutralCount++;
        break;
      case 'Negative':
        negativeCount++;
        break;
      case 'Positive':
        positiveCount++;
        break;
      default:
        break;
    }
  });

  // Create the new array in the desired format with color mapping
  const data = [
    { value: positiveCount, analysis: 'Positive' },
    { value: negativeCount, analysis: 'Negative' },
    { value: neutralCount, analysis: 'Neutral' },
  ];

  const handleDownload = () => {
    const svgNode = chRef.current;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const svgString = new XMLSerializer().serializeToString(svgNode);

    canvas.width = svgNode.clientWidth;
    canvas.height = svgNode.clientHeight;
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const imageURL = canvas.toDataURL();
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'Distribution_of_News_Sentiment.png';
      link.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
  };
  const width = Math.round(hw.width / 6) > 350 ? Math.round(hw.width / 7) : Math.round(hw.width / 6) > 230 && Math.round(hw.width / 6) < 350 ? Math.round(hw.width / 5.8) : 250;
  const height = width;
  const margin = 0;

  useEffect(() => {
    const svg = d3.select(chRef.current);

    if (!svg.select("svg").empty()) {
      svg.select("svg").remove();
    }

    const radius = Math.min(width, height) / 2 - margin;
    const legendPosition = d3
      .arc()
      .innerRadius(radius / 1.75)
      .outerRadius(radius);

    const svgContainer = svg
      .append("svg")
      .attr("width", width - 10)
      .attr("height", width - 65)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const pie = d3.pie().value((d) => d.value);
    const data_ready = pie(data);

    svgContainer
      .selectAll("whatever")
      .data(data_ready)
      .enter()
      .append("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(radius / 1.75)
          .outerRadius(radius)
      )
      .attr("fill", (d) => analysisColors[d.data.analysis])
      .attr("stroke", "#fff")
      .style("stroke-width", "2")
      .style("opacity", "0.8");

    svgContainer
      .selectAll("mySlices")
      .data(data_ready)
      .enter()
      .append("g")
      .attr("transform", (d) => `translate(${legendPosition.centroid(d)})`)
      .attr("class", "legend-g")
      .style("user-select", "none");
    svgContainer
      .selectAll(".legend-g")
      .append("text")
      .text((d) => d.data.value)
      .style("fill", "#112233")
      .style("font-size", width < 210 ? "120%" : width > 320 ? "200%" : "150%")
      .style("text-anchor", "middle");
  }, [data]);

  return (
    <div
      style={backgroundStyle}
      onMouseEnter={() => {
        setShowDownloadButton(true)
        setBackgroundStyle({ position: 'relative', backgroundColor: "#f0efef" })
      }}
      onMouseLeave={() => {
        setShowDownloadButton(false)
        setBackgroundStyle({ position: 'relative', radius: "50px" })
      }}
    >
      <svg
        width={width - 10}
        height={width - 65}
        ref={chRef}
        style={{ position: 'relative', marginTop: "10px" }}
      >
        <g
          transform={`translate(${hw.width / 12},${hw.width / 12})`}
        >
        </g>
      </svg>

      {showDownloadButton && (
        <button
          className="btn "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor: "#626252"
          }}
          onClick={handleDownload}
        >
          <MdOutlineFileDownload style={{ color: "white" }} />
        </button>
      )}
    </div>
  );
};

export default DonutChart;
