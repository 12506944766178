import { Table, Modal, Button } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import { FaHome, FaUser, FaSearch, FaPencilAlt, FaEye } from "react-icons/fa";
import { Utils } from "index";
const ViewCategory = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {

    if (show) {

      fetch(Utils.baseurl + 'category/topic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "id": props.id }),
      })
        .then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          let data = []
          if (result.data.length) {
            result.data.map(item => {
              data.push(item.topic.topic)
            })

            setData(data);
          }
        })
        .catch((error) => console.error(error));
    }


  }, [show]);

  return (
    <>
      <Button style={{ backgroundColor: "white", borderColor: "lightGray" }} onClick={handleShow}>
        <FaEye
          style={{
            width: 25,
            color: "black"
          }}
        />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Topics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.map(item => (
            <h1 style={{ fontSize: "22px", marginBottom: "5px" }}>{item}</h1>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ViewCategory;