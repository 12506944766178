import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from 'rsuite';
import Select from 'react-select'

import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import DonutChart from "./dashboard/DonutChart";
import BarChart from "./dashboard/BarChart";
import StripedColumnsExample from "./dashboard/table";
import LineChart from "./dashboard/LineChart";
import HorizontalBarChart from "./dashboard/HorizontalBarChart";
import TopicBarChart from "./dashboard/TopicBarChart";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"
import { TiRefresh } from "react-icons/ti"
import { TbFilterSearch, TbLayoutGrid, TbLayoutNavbarCollapseFilled } from "react-icons/tb";
import { FiLayout } from "react-icons/fi";
import { MdRefresh, MdOutlineFileDownload } from "react-icons/md";
import { Button } from "react-bootstrap";
import { Utils } from "index";
import { FaTable } from "react-icons/fa6";
import { FaChartBar ,FaBars } from "react-icons/fa";
import { PiExportBold } from "react-icons/pi";
import { LuLayoutPanelLeft } from "react-icons/lu";
import { RiTableFill } from "react-icons/ri";



export default function UserReports() {
  const [apiData, setApiData] = useState([])
  const [isHovered, setIsHovered] = useState(false);
  const [refreshButton, setRefreshButton] = useState(true)
  const [dateRange, setDateRange] = useState([]);
  const [topicApiData, setTopicApiData] = useState([])
  const [horizontalData, setHorizontalData] = useState([])
  const [barData, setBarData] = useState([])
  const [viewport, setViewport] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [topicOptions, setTopicOptions] = useState([])
  const [sourcesOptions, setSourcesOptions] = useState([])
  const [categoryids, setCategoryids] = useState([])
  const [sourceids, setSourceids] = useState([])
  const [topicids, setTopicids] = useState([])
  const [categorystr, SetCategorystr] = useState("Categories")
  const [topicstr, SetTopicstr] = useState("Topics")
  const [sourcestr, SetSourcestr] = useState("News Sources")
  const [tableTop, setTableTop] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [div1, setDiv1] = useState("none")
  const [div2, setDiv2] = useState("none")
  const [div3, setDiv3] = useState("none")
  const [layoutIcnStyle, setLayoutIcnStyle] = useState({ cursor: "pointer" })
  // const [div4, setDiv4] = useState("none")
  useEffect(() => {
    const handleResize = () => {
      setViewport({ width: window.innerWidth, height: window.innerHeight });
    };


    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // FiLayout  TbLayoutNavbarCollapseFilled FaTable FaChartBar

  let layoutIcon = ""

  if (div1 == "") {
    layoutIcon = "FiLayout"
  }
  if (div2 == "") {
    layoutIcon = "TbLayoutNavbarCollapseFilled"
  }
  if (div3 == "") {
    layoutIcon = "FaTable"
  }


  // if (div4 == "") {
  //   layoutIcon = "FaChartBar"
  // }

  const rotateStyle = {
    transform: isHovered ? 'rotate(45deg)' : 'rotate(0)',
    animation: isHovered ? 'spin 1s linear infinite' : 'none',
    border: 0,
    fontSize: "31px",
    marginRight: "45px",
    marginLeft: "46px"
  };

  const categoryCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      // width: 'auto',
      // minWidth: '300px',
      borderColor: state.isFocused ? '#484848' : provided.borderColor, // Change 'blue' to your desired color
      boxShadow: state.isFocused ? '0 0 0 1px #484848' : provided.boxShadow, // Optional: to add shadow
      '&:hover': {
        borderColor: '#484848',
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '200px',
    }),
    // Add any other styles you want to customize here
  };
  const topicCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#484848' : provided.borderColor, // Change 'blue' to your desired color
      boxShadow: state.isFocused ? '0 0 0 1px #484848' : provided.boxShadow, // Optional: to add shadow
      '&:hover': {
        borderColor: '#484848',
      },
      // width: 'auto',
      // minWidth: '300px',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '250px',
    }),
    // Add any other styles you want to customize here
  };
  const sourceCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#484848' : provided.borderColor, // Change 'blue' to your desired color
      boxShadow: state.isFocused ? '0 0 0 1px #484848' : provided.boxShadow, // Optional: to add shadow
      '&:hover': {
        borderColor: '#484848',
      },
      // width: 'auto',
      // minWidth: '300px',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
      minWidth: '250px',
    }),
    // Add any other styles you want to customize here
  };

  const refreshbtnStyle = {
    justifyContent: "space-evenly", width: "95%", height: "100%", fontSize: "large", backgroundColor: "#16a0e0", color: "white", border: "0px"
  }

  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  function getDashBoardData(value) {
    fetch(Utils.baseurl + 'newsapidata', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        setApiData(result);
        if (result.length == 0) {
          setIsLoading(false)
        }
      })
      .catch((error) => console.error(error));



    // api for topic bar chart

    fetch(Utils.baseurl + 'horizontal/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        let topicdata = result.map(item => ({
          category: item.title,
          newsDataArray: item.newsDataArray
        }));
        setHorizontalData(topicdata);
      })
      .catch((error) => console.error(error));



    fetch(Utils.baseurl + 'news/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        let topicdata = result.map(item => ({
          Topic: item.title,
          Value: item.count
        }));
        setTopicApiData(topicdata);
      })
      .catch((error) => console.error(error));


    fetch(Utils.baseurl + 'bar/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(value),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        // let topicdata = result.map(item => ({
        //   Topic: item.category,
        //   Value: item.newsDataArray.length
        // }));
        setBarData(result);
      })
      .catch((error) => console.error(error));



    fetch(Utils.baseurl + 'get/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        categoryName: "",
        status: "active"
      }),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        const newArray = result.map(item => ({
          value: item.id,
          label: item.title
        }));

        setCategoriesOptions(newArray);
      })
      .catch((error) => console.error(error));


      let topicReqData = {
        topicName:"",
        status:"active"
      }
     fetch(Utils.baseurl+'get/topic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(topicReqData),
        })
        .then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          const newArray = result.map(item => ({
            value: item.id,
            label: item.topic
          }));
  
          setTopicOptions(newArray)
        })
        .catch((error) => console.error(error));

    let data = {
      sourceName: "",
      status: "active"
    }
    fetch(Utils.baseurl + 'sources', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        const newArray = result.map(item => ({
          value: item.id,
          label: item.name
        }));
        setSourcesOptions(newArray)
      })
      .catch((error) => console.error(error));
  }




  useEffect(() => {
    let reqObj = {
      publishedAt: [],
      categoryids: [],
      sourceids: [],
      topicids: []
    }
    getDashBoardData(reqObj)
    if (viewport.width < 1300) {
      setLayoutIcnStyle({ cursor: "pointer" })
    } else {
      setLayoutIcnStyle({ cursor: "pointer" })
    }
  }, [viewport.width]);

  function handleRefresh() {
    setRefreshButton(false)
    setIsHovered(true);
    fetch(Utils.baseurl + "refresh/feed", requestOptions)
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        let reqObj = {
          publishedAt: [],
          categoryids: [],
          sourceids: [],
          topicids: []
        }
        getDashBoardData(reqObj)
        setIsHovered(false);
        setRefreshButton(true)
      })
      .catch((error) => console.error(error));

  }

  const handleDateRangeChange = (value) => {
    // 'value' contains the selected date range
    setDateRange(value);

    // getDashBoardData(value)

  };

  const handleTopicChange = (value) => {
    const topicId = parseInt(value);

    if (topicids.includes(topicId)) {
      // If the category is already selected, remove it
      setTopicids(prevCategoryIds => prevCategoryIds.filter(id => id !== topicId));
    } else {
      // If the category is not selected, add it
      setTopicids(prevCategoryIds => [...prevCategoryIds, topicId]);
    }
  };

  const topicCheckboxOption = ({ children, value }) => (
    <div style={{display:"flex"}}>
      <input
        type="checkbox"
        value={value}
        onChange={() => handleTopicChange(value)}
        checked={topicids.includes(parseInt(value))}
        style={{ marginRight: "5px", marginLeft: "5px" }}
      />
      <label style={{fontSize: "16px"}}>{children}</label>
    </div>
  );

  const handleCategoryChange = (value) => {
    const categoryId = parseInt(value);

    if (categoryids.includes(categoryId)) {
      // If the category is already selected, remove it
      setCategoryids(prevCategoryIds => prevCategoryIds.filter(id => id !== categoryId));
    } else {
      // If the category is not selected, add it
      setCategoryids(prevCategoryIds => [...prevCategoryIds, categoryId]);
    }
  };


  const categoryCheckboxOption = ({ children, value }) => (
    <div style={{display:"flex"}}>
      <input
        type="checkbox"
        value={value}
        onChange={() => handleCategoryChange(value)}
        checked={categoryids.includes(parseInt(value))}
        style={{ marginRight: "5px", marginLeft: "5px" }}
      />
      <label style={{fontSize: "16px"}}>{children}</label>
    </div>
  );

  const handleSourceChange = (value) => {
    const sourceId = parseInt(value);

    if (sourceids.includes(sourceId)) {
      // If the source is already selected, remove it
      setSourceids(prevSourceIds => prevSourceIds.filter(id => id !== sourceId));
    } else {
      // If the source is not selected, add it
      setSourceids(prevSourceIds => [...prevSourceIds, sourceId]);
    }
  };



  const sourceCheckboxOption = ({ children, value }) => (
    <div style={{display:"flex"}}>
      <input
        type="checkbox"
        value={value}
        onChange={() => handleSourceChange(value)}
        checked={sourceids.includes(parseInt(value))}
        style={{ marginRight: "5px", marginLeft: "5px" }}
      />
      <label style={{fontSize: "16px"}}>{children}</label>
    </div>
  );

  const handleFilter = () => {
    // Handle export button click, you can access selectedCategories and selectedSources here

    let reqObj = {
      publishedAt: dateRange,
      categoryids: categoryids,
      sourceids: sourceids,
      topicids:topicids
    }
    getDashBoardData(reqObj)
  };




  const handleExport = async () => {
    let reqObj = {
      publishedAt: dateRange,
      categoryids: categoryids,
      sourceids: sourceids,
      topicids:topicids
    };

    fetch(Utils.baseurl + 'export-csv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqObj),
    })
      .then((response) => {
        // Check if the response is a success
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Return the blob response
        return response.blob();
      })
      .then(async (blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        let d =new Date()
        let date = d.getTime()
        link.setAttribute('download', `sentiment-data-${date}.csv`);
        // Append the link to the document body and click it to trigger download
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (categoryids.length == 0) {
      SetCategorystr("Categories")
    } else {

      SetCategorystr(`${categoryids.length} Selected`)
    }

  }, [categoryids]);


  useEffect(() => {
    if (topicids.length == 0) {
      SetTopicstr("Topics")
    } else {

      SetTopicstr(`${topicids.length} Selected`)
    }

  }, [topicids]);



  


  useEffect(() => {
    setDiv1(tableTop == 1 ? "" : "none")
    setDiv2(tableTop == 2 ? "" : "none")
    setDiv3(tableTop == 3 ? "" : "none")
    // setDiv4(tableTop == 4 ? "" : "none")
  }, [tableTop]);


  useEffect(() => {
    if (sourceids.length == 0) {
      SetSourcestr("News Sources")
    } else {

      SetSourcestr(`${sourceids.length} Selected`)
    }

  }, [sourceids]);


  function handleUi() {
    if (tableTop < 3) {
      setTableTop(tableTop + 1)
    } else {
      setTableTop(1)
    }

  }

let dateRangeStyle = viewport.width<1250?{width:"35%"}:{minWidth:"250px"}
const handleKeyDown = (e) => {
  if (e.key === 'Enter'|| e.key === ' ') {
    e.preventDefault();
  }
};

  return (
    <div style={{ maxWidth: "100%", width: "100%", overflowX: "hidden", height: "110%", overflowY: "hidden" }}>
      <div className="container-fluid " style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}  >


        <div style={{ display: "flex", alignItems: "center", height: "38px"}}>
          <div style={dateRangeStyle}>
          <DateRangePicker
            showWeekNumbers
            onChange={handleDateRangeChange}
            placeholder="     Startdate  ~  Enddate"
            value={dateRange} // controlled component, should be set to the state
          />
          </div>
          <div style={{ marginLeft: "6px" ,minWidth:"140px"}}>
            <Select
                styles={categoryCustomStyles}
              options={categoriesOptions}
              closeMenuOnSelect={false}
              components={{ Option: categoryCheckboxOption }}
              placeholder={categorystr}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div style={{ marginLeft: "6px" ,minWidth:"140px" }}>
            <Select
              styles={topicCustomStyles}
              options={topicOptions}
              isMulti
              closeMenuOnSelect={false}
              components={{ Option: topicCheckboxOption }}
              placeholder={topicstr}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div style={{ marginLeft: "6px" ,minWidth:"160px"}}>
            <Select
            styles={sourceCustomStyles}
              options={sourcesOptions}
              isMulti
              closeMenuOnSelect={false}
              components={{ Option: sourceCheckboxOption }}
              placeholder={sourcestr}
              onKeyDown={handleKeyDown}
            />
          </div>


          <div style={{ marginLeft: "6px", marginRight: "6px",marginTop: "6px" }}>
            <button className="custom-button " onClick={handleFilter}>
              <TbFilterSearch style={{marginRight:"4px",marginLeft:"4px",height:"24px",width:"24px"}} />
            </button>
          </div>

        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-" }}>
        <div style={{ cursor: "pointer"}} onClick={handleUi} >
          {div1 == "" ? <button className="custom-button " style={{marginRight:"10px",marginTop: "6px"}} > <LuLayoutPanelLeft style={{ marginRight:"4px",marginLeft:"4px",height:"25px",width:"25px" }} />  </button> :
            div2 == "" ? <button className="custom-button " style={{marginRight:"10px",marginTop: "6px"}}> <RiTableFill style={{ marginRight:"4px",marginLeft:"4px",height:"25px",width:"25px" }} />  </button>  :
            <button className="custom-button " style={{marginRight:"10px",marginTop: "6px"}}> <FaChartBar style={{ marginRight:"2px",marginLeft:"6px",height:"25px",width:"23px" }} />  </button>     }
          {/* <FiLayout style={{ fontSize: "35px", color: "gray", marginLeft: "-17%" }} /> */}

        </div>

          <div>
            <button className="custom-button d-flex align-items-center" style={{ justifyContent: "space-evenly", width: "110px", height: "36px", fontSize: "large", color: "white", border: "0px" , borderRadius:"6px" }} onClick={handleExport} >
              <PiExportBold style={{height:"20px",width:"25px"}}/><b>Export</b>
            </button>
          </div>
          {/* <div style={{ marginLeft: "2px" }}>
            {refreshButton ? <Button className="d-flex align-items-center btn-sm" style={refreshButton ? refreshbtnStyle : rotateStyle} onClick={() => handleRefresh()}>
              <> <MdRefresh /> <b>Refresh</b> </>
            </Button> : <MdRefresh style={refreshButton ? refreshbtnStyle : rotateStyle} />}
          </div> */}
        </div>
      </div>
      <div className="container-fluid" style={{ display: div1 }}>
        <div className="mainbody" style={{ marginTop: 10 }}>

          <div className="container-c col-12 col-md-12 col-lg-12" style={{ height: "auto" ,marginBottom:"30px" }}>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "5px" }}>
              <div >
                <div className="container-f">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}> Articles Over Category</h2></b>
                  <TopicBarChart data={topicApiData} hw={viewport} />
                </div>
              </div>
              <div >
                <div className="container-g">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}> Sentiment Over Category</h2></b>
                  <HorizontalBarChart data={horizontalData} hw={viewport} />
                </div>
              </div>
              <div >
                <div className="mt-1 container-g">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Distribution of News Sentiment</h2></b>
                  <DonutChart donutData={apiData} hw={viewport} />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" ,marginTop:"40px"}}>
              <div >
                <div className="container-f">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Sentiment Over Time</h2></b>
                  <LineChart lineData={apiData} hw={viewport} />
                </div>
              </div>

              <div className="container-g">
              <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Sentiment By Month</h2></b>
                <BarChart data={barData} hw={viewport} />
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-12 col-lg-12 tablediv"
          >
            <StripedColumnsExample data={apiData} hw={viewport} parentfn={getDashBoardData} loading={isLoading} />
          </div>
        </div>
      </div>


      <div className="container-fluid" style={{ display: div2 }}>
        <div className=" mainbody" style={{ marginTop: 10 }}>
          <div
            className="col-12 col-md-12 col-lg-12 tablediv"
            style={{ height: "100vh", overflowY: "scroll"}}
          >
            <StripedColumnsExample data={apiData} hw={viewport} parentfn={getDashBoardData} loading={isLoading} />
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ display: div3 }}>
        <div style={{ marginTop: 10 }}>

          <div className="container-c col-12 col-md-12 col-lg-12" style={{ height: "100vh" }}>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "5px" }}>
              <div >
                <div className="container-f">
          <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}> Articles Over Category</h2></b>
                  <TopicBarChart data={topicApiData} hw={viewport} />
                </div>
              </div>
              <div >
                <div className="container-g">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Sentiment Over Category</h2></b>
                  <HorizontalBarChart data={horizontalData} hw={viewport} />
                </div>
              </div>
              <div >
                <div className="mt-1 container-g">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Distribution of News Sentiment</h2></b>
                  <DonutChart donutData={apiData} hw={viewport} />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "40px" }}>
              <div >
                <div className="container-f">
                <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Sentiment Over Time</h2></b>
                  <LineChart lineData={apiData} hw={viewport} />
                </div>
              </div>

              <div className="container-g">
              <b><h2 className="mt-1" style={{fontSize:"18px",textAlign:"center"}}>Sentiment By Month</h2></b>
                <BarChart data={barData} hw={viewport} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ display: "none" }}>
        <div className="row mainbody" style={{ marginTop: 2 }}>
          <div
            className="col-12 col-md-6 col-lg-6 tablediv"
            style={{ height: "100vh", overflowY: "scroll", paddingRight: 10 }}
          >
            <StripedColumnsExample data={apiData} hw={viewport} parentfn={getDashBoardData} loading={isLoading} />
          </div>
          <div className="container-c  col-12 col-md-6 col-lg-6" style={{ height: "100vh" }}>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="container-g">
                  <HorizontalBarChart data={horizontalData} hw={viewport} />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="container-f">
                  <TopicBarChart data={topicApiData} hw={viewport} />
                </div>
              </div>

            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="container-f">
                  <LineChart lineData={apiData} hw={viewport} />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-6 col-xl-6 col-xxl-6">
                <div style={{ marginTop: "20px" }} >
                  <DonutChart donutData={apiData} hw={viewport} />
                </div>
              </div>
            </div>
            <BarChart data={barData} hw={viewport} />
          </div>
        </div>
      </div>
    </div>);
}
