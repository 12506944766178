import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaRegPlusSquare } from "react-icons/fa";
import { Utils } from "index";
import Select from 'react-select'
const AddTopic = (props) => {
  const [show, setShow] = useState(false);
  const [topic, setTopic] = useState(""); // State to manage input field value

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [categoryids, setCategoryids] = useState([])

  const [selectedOption, setSelectedOption] = useState(1);

  const [categoriesOptions, setCategoriesOptions] = useState([])

  const [categorystr, SetCategorystr] = useState("Categories")


  // Function to handle input field change
  const handleInputChange = (e) => {
    setTopic(e.target.value);
  };

  useEffect(() => {


    fetch(Utils.baseurl + 'get/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        categoryName: "",
        status: ""
      }),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        const newArray = result.map(item => ({
          value: item.id,
          label: item.title
        }));

        setCategoriesOptions(newArray);
      })
      .catch((error) => console.error(error));

  }, []);


  // Function to handle saving changes
  const handleSaveChanges = async () => {

    let topicStr = topic.trim()
    let data = { topic: topic.trim(), categoryId: categoryids }
    if (categoryids.length && topicStr.length) {
      fetch(Utils.baseurl + 'add/topic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          if (result.status == false) {
            alert(result.message)
          }
          if (result.status == true) {
            props.renderFun()
          }
        })
        .catch((error) => console.error(error));
      setTopic("")
      setCategoryids([])
      handleClose(); // Close the modal after saving changes

    } else {
      alert("Please Enter Topic and select atleast one Category")
    }


  };


  useEffect(() => {
    if (categoryids.length == 0) {
      SetCategorystr("Categories")
    } else {

      SetCategorystr(`${categoryids.length} Selected`)
    }

  }, [categoryids]);


  const handleCategoryChange = (value) => {


    const categoryId = parseInt(value);

    if (categoryids.includes(categoryId)) {
      // If the category is already selected, remove it
      setCategoryids(prevCategoryIds => prevCategoryIds.filter(id => id !== categoryId));
    } else {
      // If the category is not selected, add it
      setCategoryids(prevCategoryIds => [...prevCategoryIds, categoryId]);
    }
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: '38px',
      // Add any other inline styles here
    }),
  };



  const categoryCheckboxOption = ({ children, value }) => {
    return <div>
      <label htmlFor={`category_checkbox12-${value}`}>
        <input
          type="checkbox"
          id={`category_checkbox12-${value}`} // Unique ID for each checkbox
          value={value}
          onChange={() => handleCategoryChange(value)}
          checked={categoryids.includes(parseInt(value))}
          style={{ marginRight: "5px", marginLeft: "5px" }}
        />
        {children}</label> {/* Match label's htmlFor with checkbox's id */}
    </div>
  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
    }
  };

  return (
    <>
      <Button className="d-flex align-items-center " style={{ justifyContent: "space-evenly", width: "170px", height: "38px", fontSize: "larger", backgroundColor: "#484848", color: "white", border: "0px", borderRadius: "8px" }} onClick={handleShow}>
        <FaRegPlusSquare /><b>Add new</b>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input field for editing title */}
          <div className="d-flex" style={{ marginBottom: "10px", fontSize: "large" }}>
            <div style={{ marginLeft: "12px" }}><b>Topic</b></div>
            <div style={{ marginLeft: "190px" }}><b>Category</b></div>
          </div>

          <div className="d-flex" style={{ justifyContent: "space-around" }}>

            <Form.Control
              type="text"
              value={topic}
              placeholder="Enter Topic Name"
              onChange={handleInputChange}
              style={{ fontSize: "16px", width: "45%" }}
            />
            <div style={{ width: "45%", marginLeft: "2px" }}>
              <Select
                options={categoriesOptions}
                isMulti
                closeMenuOnSelect={false}
                components={{ Option: categoryCheckboxOption }}
                placeholder={categorystr}
                styles={customStyles}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#484848", borderColor: "#484848" }} onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTopic;
