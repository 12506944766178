// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-div2 {
    display: flex;
    justify-content: space-around;
    font-size: 25px;
    font-weight: bolder;
    margin-top: 20px;
  }
  label {
    margin-right: 17px;
    font-size: 18px;
  }
  /* .searchlabel {
    font-size: 24px;
    margin: 20px 25px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  } */


  /* .category-div1 {
    min-height: 200px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #b6b3b3;
  }
   */
  input {
    border: 1px solid #b6b3b3;
    border-radius: 5px;
  }
  /* .searchbutton {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  } */
  
  
  /* table tbody tr .categorytd2 {
    font-size: 20px;
    width: 45%;
    color: #3f3b3b;
  } */
  .categorytd4,
  .categorytd5 {
    text-align: center;
  }
  /* table thead tr .categoryhead {
    border: 1px solid #abafb3;
    font-size: 21px;
    text-align: center;
  } */
  /* table tbody tr .categorybody {
    vertical-align: middle;
    border: 1px solid #abafb3;
    font-size: 20px;
  } */
  table tbody tr .categorytd3 {
    border: 0px solid #abafb3;
    border-bottom: 1px solid #abafb3;
  }
  .editDiv,
  .statusDiv,
  .viewDiv {
    display: flex;
    justify-content: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/views/admin/source/source.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;IACnB,gBAAgB;EAClB;EACA;IACE,kBAAkB;IAClB,eAAe;EACjB;EACA;;;;;;KAMG;;;EAGH;;;;;;IAME;EACF;IACE,yBAAyB;IACzB,kBAAkB;EACpB;EACA;;;;KAIG;;;EAGH;;;;KAIG;EACH;;IAEE,kBAAkB;EACpB;EACA;;;;KAIG;EACH;;;;KAIG;EACH;IACE,yBAAyB;IACzB,gCAAgC;EAClC;EACA;;;IAGE,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".category-div2 {\n    display: flex;\n    justify-content: space-around;\n    font-size: 25px;\n    font-weight: bolder;\n    margin-top: 20px;\n  }\n  label {\n    margin-right: 17px;\n    font-size: 18px;\n  }\n  /* .searchlabel {\n    font-size: 24px;\n    margin: 20px 25px 0px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  } */\n\n\n  /* .category-div1 {\n    min-height: 200px;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    border: 1px solid #b6b3b3;\n  }\n   */\n  input {\n    border: 1px solid #b6b3b3;\n    border-radius: 5px;\n  }\n  /* .searchbutton {\n    display: flex;\n    justify-content: center;\n    margin: 20px 0px;\n  } */\n  \n  \n  /* table tbody tr .categorytd2 {\n    font-size: 20px;\n    width: 45%;\n    color: #3f3b3b;\n  } */\n  .categorytd4,\n  .categorytd5 {\n    text-align: center;\n  }\n  /* table thead tr .categoryhead {\n    border: 1px solid #abafb3;\n    font-size: 21px;\n    text-align: center;\n  } */\n  /* table tbody tr .categorybody {\n    vertical-align: middle;\n    border: 1px solid #abafb3;\n    font-size: 20px;\n  } */\n  table tbody tr .categorytd3 {\n    border: 0px solid #abafb3;\n    border-bottom: 1px solid #abafb3;\n  }\n  .editDiv,\n  .statusDiv,\n  .viewDiv {\n    display: flex;\n    justify-content: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
