import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { FaHome, FaUser, FaSearch, FaPencilAlt, FaEye } from "react-icons/fa";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { DateRangePicker } from 'rsuite';
import { MdDeleteOutline, MdRadioButtonUnchecked } from "react-icons/md";
import { IoMdRadioButtonOn } from "react-icons/io";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import "./topic.css";
import AddTopic from "./addTopicModel";
import EditTopic from "./EditTopicModal";
import { Utils } from "index";
const { combine, before, after } = DateRangePicker;

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [apiData, setApiData] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [topicName, setTopicName] = useState('');
  const [status, setStatus] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [dateRange, setDateRange] = useState([]);
  const [options, setOptions] = useState([
    { id: 1, title: 'Health' },
    { id: 2, title: 'Technology' },
    { id: 3, title: 'Politics' },
    { id: 4, title: 'Science' },
    { id: 5, title: 'Business' }
  ]);
  const [isCatAsc, setIsCatAsc] = useState(true)
  const [switchValue, setSwitchValue] = useState(false);

  const [arrIndex, setArrIndex] = useState(null)
  const [topic, setTopic] = useState(null)
  const [topicId, setTopicId] = useState(null)


  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  function renderList() {
    setIsLoading(true)
    let data = {
      topicName: topicName,
      status: status
    }
    fetch(Utils.baseurl + 'get/topic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        let NewApiData = [...apiData]
        NewApiData = result
        setApiData(NewApiData)
        setIsLoading(false)
      })
      .catch((error) => console.error(error));



    fetch(Utils.baseurl + 'get/category', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        categoryName: "",
        status: ""
      }),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        setOptions(result);
        setLoader(false)
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    renderList()
  }, []);



  async function handleStatus(data) {

    data.is_active == 1 ? data.is_active = 0 : data.is_active = 1
    const response = await fetch(Utils.baseurl + 'update/topic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    renderList()
  }

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const handleSearch = () => {
    renderList()
    // Log the values for testing (you can replace this with your desired functionality)

  };



  const handleCheckboxChange = async (item) => {
    setLoader(true)
    setSwitchValue(!switchValue);

    let data = { status: switchValue ? 0 : 1 }
    fetch(Utils.baseurl + 'update/topic/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        if (result.status) {
          renderList()
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (dateRange.length) {
      let NewApiData1 = [...apiData]

      NewApiData1[arrIndex].showDatePicker = 0
      setApiData(NewApiData1)

      NewApiData1[arrIndex].fetching = 1
      setApiData(NewApiData1)

      let data = { topic: topic, topicId: topicId, dateRange: dateRange }

      if (typeof (dateRange[0]) == 'object' && typeof (dateRange[1]) == 'object') {
        fetch(Utils.baseurl + 'fetch/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((response) => response.json()) // Parse response as JSON
          .then((result) => {

            if (result.status == false) {
              let NewApiData1 = apiData
              NewApiData1[arrIndex].fetching = 2
              setApiData(NewApiData1)
            }
            setDateRange([])
            setTopic(null)
            setTopicId(null)
            renderList()
          })
          .catch((error) => console.error(error));
      }
      setDateRange([])
      setTopic(null)
      setTopicId(null)

    }
  }, [dateRange]);

  let today = new Date();
  let fromdate = new Date();
  fromdate.setDate(fromdate.getDate() - 30);


  const handleFetchData = async (item) => {
    let NewApiData = [...apiData]
    setArrIndex(item.index)
    NewApiData[item.index].showDatePicker = 1
    setApiData(NewApiData)


    setTopic(item.topic)

    setTopicId(item.topicId)

    let data = { topic: item.topic, topicId: item.topicId }



    if (item.is_active == 1 && dateRange.length) {

    }


  }


  function handleCallRenderList() {
    renderList()
  }

  const handleCategorySort = async () => {

    setIsCatAsc(!isCatAsc)
    let newData = apiData
    if (isCatAsc) {
      newData.sort((a, b) => {
        const titleA = a.topic.toLowerCase();
        const titleB = b.topic.toLowerCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.topic.toLowerCase();
        const titleB = b.topic.toLowerCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }



  const handleDateRangeChange = (value) => {
    // 'value' contains the selected date range
    setDateRange(value);

  };



  const handleDelete = (item) => {
    const result = window.confirm('Are you sure you want to delete this topic? All articles related to this topic will be deleted.');
    if (result) {
      fetch(Utils.baseurl + 'delete/topic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }).then((response) => response.json()) // Parse response as JSON
        .then((result) => {
          renderList()
        })
        .catch((error) => console.error(error));
    }

  };




  return (
    <div>
      <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ fontSize: "25px", fontWeight: "bold", marginLeft: "10px" }}>Topics</div>
        <div >
          <button
            onClick={toggleModal}
            className="btn d-flex align-items-center"
          >
            <AddTopic show={modalShow} onHide={toggleModal} options={options} renderFun={handleCallRenderList} />


          </button>
        </div>
      </div>


      <div className="container-fluid">
        <div className="category-div1 col-12 col-lg-12 bg-white">
          <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>

            <label className="searchlabel">
              <FaSearch style={{ marginRight: 8 }} />
              Search
            </label>
          </div>
          <div className="category-divt w-100">
            <div >
              <label htmlFor="category-name">Topic Name</label>
              <input
                type="text"
                id="category-name"
                placeholder="Enter Name"
                style={{
                  width: "200px",
                  border: "1px solid #abafb3",
                  borderRadius: "5px",
                  paddingLeft: "12px",
                  fontSize: "16px",
                  height: "35px",
                  fontWeight: "normal",
                  color: "black"
                }}
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
              />
            </div>
            <div >
              <label htmlFor="status">Status</label>
              <select
                id="status"
                style={{
                  width: "160px",
                  border: "1px solid #abafb3",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontSize: "16px",
                  height: "35px"
                }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="searchbutton" style={{ marginBottom: "10px" }}>
              <button
                className="btn d-flex align-items-center "

                style={{ width: "170px", height: "38px", fontSize: "large", backgroundColor: "#484848", color: "white", borderRadius: "9px" }}
                onClick={handleSearch} // Call handleSearch function on button click
              >
                <FaSearch style={{ color: "white", marginRight: 10, marginLeft: 30 }} />
                <b>
                  Search
                </b>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <Table className="table w-100 p-0">
            <thead>
              <tr>
                <th className="topichead bg-light">S.No</th>
                <th className="topichead bg-light">Topic <button onClick={() => handleCategorySort()}> {isCatAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button></th>
                <th className="topichead bg-light"> <div className="d-flex justify-content-center"> <div>Status</div>   <Form><Form.Check style={{ fill: "#484848" }} type="switch" id="custom-switch" checked={switchValue} onChange={handleCheckboxChange} /></Form></div></th>
                <th className="topichead bg-light">Edit</th>
                <th className="topichead bg-light" style={{ width: "22%" }}>Action</th>
                <th className="topichead bg-light" style={{ width: "32%" }}>Number Of Articles</th>
                <th className="topichead bg-light">Delete</th>
              </tr>
            </thead>
            <tbody>
              {apiData.length === 0 ? (
                <tr>
                  <td colSpan={7}>
                    {
                      isLoading ?
                        <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> :
                        <p style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }} >No data found</p>
                    }
                  </td>
                </tr>
              ) : apiData.map((item, index) => (
                <tr key={item.id}>
                  <td className="topicbody topictd1">{index + 1}</td>
                  <td className="topicbody topictd4" style={{ width: "19%" }}>{item.topic}</td>
                  <td className="topicbody topictd3" style={{ width: "13%" }}>
                    <div className="statusDiv">
                      {
                        loader ? <FontAwesomeIcon icon={faSpinner} spin size="1x" /> :

                          <Form>   {item.is_active === 1 ? (
                            <Form.Check type="switch" id="custom-switch" checked={true} onClick={() => handleStatus({
                              id: item.id,
                              is_active: item.is_active
                            })} />

                          ) : (
                            <Form.Check type="switch" id="custom-switch" checked={false} onClick={() => handleStatus({
                              id: item.id,
                              is_active: item.is_active
                            })} />

                          )}
                          </Form>
                      }
                    </div>
                  </td>
                  <td className="topicbody topictd4" style={{ width: "7%" }}>
                    <div className="editDiv">
                      <button

                        onClick={toggleModal}
                      >
                      </button>
                      <EditTopic show={modalShow} onHide={toggleModal} data={{ topic: item.topic, id: item.id }} options={options} renderFun={handleCallRenderList} />
                    </div>
                  </td>
                  <td className="topicbody topictd4">
                    {
                      item.showDatePicker == 1 ?
                        <DateRangePicker
                          style={{ width: "100%" }}
                          showWeekNumbers
                          onChange={handleDateRangeChange}
                          placeholder=" Startdate  ~  Enddate"
                          value={dateRange} // controlled component, should be set to the state
                          shouldDisableDate={combine(before(fromdate), after(today))}
                          showOneCalendar
                        /> :

                        <button style={{ width: "120px", backgroundColor: "#484848", border: "0", color: "white" }} className={item.fetching == 1 || item.fetching == 2 || item.is_active == 0 ? "btn btn-sm disabled" : "btn  btn-sm"}
                          onClick={() => handleFetchData({ topic: item.topic, is_active: item.is_active, topicId: item.id, index: index, dateRange: item.dateRange })}>
                          <b>{item.fetching == 1 ? "Fetching..." : item.fetching == 2 ? "No Data Found" : item.articleCount > 0 ? "Refetch" : "Fetch"}</b></button>} </td>
                  <td className="topicbody topictd4">
                    Fetched <b> {item.count} </b>articles from <b>{item.sourceCount}</b> platforms {item.dateRange === 'null' ? "" : <hr />} <p>{item.dateRange === 'null' ? "" : item.dateRange}</p>
                  </td>
                  <td className="topicbody ">
                    <button className="btn" style={{ border: "0px solid gray" }} onClick={() => handleDelete({ id: item.id })} ><MdDeleteOutline size="25px" /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
