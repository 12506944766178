import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import { Utils } from "index";
const EditCategory = (props) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(props.data); // State to manage input field value

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Function to handle input field change
  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  // Function to handle saving changes
  const handleSaveChanges = async () => {

    let titleStr = title.trim()
    if (titleStr.length) {
      let data = { id: props.data.id, title: title.trim() }
      const response = await fetch(Utils.baseurl + 'update/category', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      props.renderFun()
      handleClose(); // Close the modal after saving changes

    } else {
      alert("Please Enter Title")
    }
  };

  return (
    <>
      <Button style={{ backgroundColor: "white", display: "flex", borderColor: "lightGray", color: "black", alignItems: "center" }} onClick={handleShow}>
        <FaPencilAlt style={{ marginRight: 10, marginLeft: 10, color: "black" }} /> Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input field for editing title */}
          <Form.Control
            type="text"
            defaultValue={props.data.title}
            onChange={handleInputChange}
            style={{ fontSize: "16px" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#484848", borderColor: "#484848" }} onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCategory;
