import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FcInfo } from "react-icons/fc";
import './model.css'

import { Utils } from "index";
import { MdVerticalAlignTop } from "react-icons/md";
const AnalysisModel = (props) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(props.data); // State to manage input field value

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let data = props.data
  data = JSON.parse(data)

  return (
    <>
      <div style={{ marginLeft: "5px", cursor: "pointer" }} onClick={handleShow} ><FcInfo size="18px" /> </div>

      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Analysis Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead style={{ verticalAlign: "middle", textAlign: "center" }}>
              <tr>
                <th>Title</th>
                <th>Score</th>
                <th>Analysis</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((key) => (
                <tr key={key}>
                  <td className="wide-column">{key}</td>
                  <td>{data[key].score}</td>
                  <td width="81%">{data[key].analysis}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AnalysisModel;
