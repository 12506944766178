import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { FaHome, FaUser, FaSearch, FaPencilAlt, FaEye } from "react-icons/fa";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { Table, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import "./source.css";
import { Utils } from "index";
//   import  ViewCategory from "./ViewCategory";

//   import EditCategory from "./EditCategoryModal";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [apiData, setApiData] = useState([])
  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [sourceName, setSourceName] = useState('');
  const [status, setStatus] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [isCatAsc, setIsCatAsc] = useState(true)
  const [switchValue, setSwitchValue] = useState(false);
  // Function to toggle modal visibility
  const toggleModal = () => {
    setModalShow(!modalShow);
  };


  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  function renderList() {
    setIsLoading(true)
    let data = {
      sourceName: sourceName,
      status: status
    }
    fetch(Utils.baseurl + 'sources', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json()) // Parse response as JSON
      .then((result) => {
        setApiData(result);
        setIsLoading(false)
      })
      .catch((error) => console.error(error));


  }

  useEffect(() => {
    renderList()
  }, []);




  async function handleStatus(data) {

    data.is_active == 1 ? data.is_active = 0 : data.is_active = 1
    const response = await fetch(Utils.baseurl + 'update/sources', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    renderList()
  }



  const handleSearch = () => {
    renderList()
    // Log the values for testing (you can replace this with your desired functionality)

  };

  const handleCheckboxChange = async () => {
    setLoader(true)
    setSwitchValue(!switchValue);

    let data = { status: switchValue ? 0 : 1 }
    const response = await fetch(Utils.baseurl + 'update/source/status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    setLoader(false)
    renderList()
  };


  const handleCategorySort = async () => {

    setIsCatAsc(!isCatAsc)
    let newData = apiData
    if (isCatAsc) {
      newData.sort((a, b) => {
        const titleA = a.name.toLowerCase();
        const titleB = b.name.toLowerCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    } else {
      newData.sort((a, b) => {
        const titleA = a.name.toLowerCase();
        const titleB = b.name.toLowerCase();

        if (titleA > titleB) {
          return -1;
        }
        if (titleA < titleB) {
          return 1;
        }
        return 0;
      });
    }


    setApiData(newData)

  }



  return (
    <div>
      <div className="d-flex my-2" style={{ padding: "2px", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ fontSize: "25px", fontWeight: "bold", marginLeft: "10px" }}>News Sources</div>
        <div >

        </div>
      </div>
      <div className="container-fluid">
        <div className="category-div1 col-12 col-lg-12 bg-white">
          <label className="searchlabel">
            <FaSearch style={{ marginRight: 8 }} />
            Search
          </label>
          <div className="category-divt ">
            <div >
              <label htmlFor="category-name">Source Name</label>
              <input
                type="text"
                id="category-name"
                placeholder="Enter Name"
                style={{
                  width: "200px",
                  border: "1px solid #abafb3",
                  borderRadius: "5px",
                  paddingLeft: "12px",
                  fontSize: "16px",
                  height: "35px",
                  fontWeight: "normal",
                  color: "black"
                }}
                value={sourceName}
                onChange={(e) => setSourceName(e.target.value)}
              />
            </div>
            <div >
              <label htmlFor="status">Status</label>
              <select
                id="status"
                style={{
                  width: "160px",
                  border: "1px solid #abafb3",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontSize: "16px",
                  height: "35px"
                }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="searchbutton" style={{ marginBottom: "20px" }}>
              <button
                className="btn d-flex align-items-center "
                style={{ width: "170px", height: "38px", fontSize: "large", backgroundColor: "#484848", color: "white", borderRadius: "9px" }}
                onClick={handleSearch} // Call handleSearch function on button click
              >
                <FaSearch style={{ color: "white", marginRight: 10, marginLeft: 30 }} />
                <b>
                  Search
                </b>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <Table className="table w-100 p-0">
            <thead>
              <tr>
                <th className="categoryhead bg-light">S.No</th>
                <th className="categoryhead bg-light">News Source  <button onClick={() => handleCategorySort()}> {isCatAsc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</button></th>
                <th className="categoryhead bg-light"> <div className="d-flex justify-content-center "> <div>Status</div>   <Form ><Form.Check type="switch" id="custom-switch" checked={switchValue} onChange={handleCheckboxChange} /></Form></div></th>

                <th className="categoryhead bg-light">Website URL</th>
              </tr>
            </thead>
            <tbody>
              {apiData.length === 0 ? (
                <tr>
                  <td colSpan={4}>
                    {
                      isLoading ?
                        <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> :
                        <p style={{ fontSize: 20, fontWeight: 500 }} >No data found</p>
                    }
                  </td>
                </tr>
              ) : apiData.map((item, index) => (
                <tr key={item.id}>
                  <td className="categorybody categorytd1">{index + 1}</td>
                  <td className="categorybody categorytd2">{item.name}</td>
                  <td className="categorybody categorytd3">
                    <div className="statusDiv">
                      {
                        loader ? <FontAwesomeIcon icon={faSpinner} spin size="1x" /> :
                          <Form>   {item.is_active === 1 ? (
                            <Form.Check type="switch" id="custom-switch" checked={true} onClick={() => handleStatus({
                              id: item.id,
                              is_active: item.is_active
                            })} />

                          ) : (
                            <Form.Check type="switch" id="custom-switch" checked={false} onClick={() => handleStatus({
                              id: item.id,
                              is_active: item.is_active
                            })} />

                          )}
                          </Form>

                      }
                    </div>
                  </td>
                  <td className="categorybody categorytd5">
                    <div className="viewDiv">
                      {/* Button to open the modal */}
                      <a href={"https://" + item.url} target="_blank">
                        <Button style={{ backgroundColor: "white", borderColor: "lightGray" }} >
                          <FaEye
                            style={{
                              width: 25,
                              color: "black"
                            }}
                          />
                        </Button>
                      </a>
                      {/* Render the modal component */}
                    </div>

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );

}
