import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Utils } from "index";
import { FaRegPlusSquare } from "react-icons/fa";

const AddCategory = (props) => {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(""); // State to manage input field value

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Function to handle input field change
    const handleInputChange = (e) => {
        setTitle(e.target.value);
    };

    // Function to handle saving changes
    const handleSaveChanges = async () => {

        let titleStr = title.trim()
        if (titleStr.length) {
            let data = { title: title.trim() }
            const response = await fetch(Utils.baseurl + 'add/category', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then((response) => response.json()) // Parse response as JSON
                .then((result) => {
                    if (result.status == false) {
                        alert(result.message)
                    }
                    if (result.status == true) {
                        props.renderFun()
                    }
                })
                .catch((error) => console.error(error));
            handleClose();
            setTitle("")
        } else {
            alert("Please Enter Title")
        }

    };

    return (
        <>


            <Button className="d-flex align-items-center " style={{ justifyContent: "space-evenly", width: "170px", height: "38px", fontSize: "larger", backgroundColor: "#484848", color: "white", border: "0px", borderRadius: "9px" }} onClick={handleShow}>
                <FaRegPlusSquare /><b>Add new</b>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Input field for editing title */}
                    <Form.Control
                        type="text"
                        value={title}
                        placeholder="Enter Category Name"
                        onChange={handleInputChange}
                        style={{ fontSize: "16px" }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button style={{ backgroundColor: "#484848", borderColor: "#484848" }} onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddCategory;
