import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Utils } from "index";
import html2canvas from 'html2canvas';
import { MdOutlineFileDownload } from "react-icons/md";
import { Button } from "react-bootstrap";
const BarChart = ({data,hw}) => {
  const svgRef = useRef();
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({position: 'relative',radius:"50px"});

function findHighestValue(arr) {
  let highestValue = -Infinity;

  arr.forEach(obj => {
    Object.values(obj).forEach(value => {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > highestValue) {
        highestValue = numericValue;
      }
    });
  });

  return highestValue;
}
const highestValue = findHighestValue(data.slice(0, -1));


// const handleDownload = () => {
//   // Get the SVG element of the chart
//   const chartNode = svgRef.current;

//   // Generate an image of the SVG element
//   html2canvas(chartNode)
//     .then(canvas => {
//       // canvas.width = canvas.width+50
//       // canvas.height = canvas.height+50
      
//       // Convert the canvas to a data URL
//       const imageURL = canvas.toDataURL();

//       // Trigger download
//       const link = document.createElement('a');
//       link.href = imageURL;
//       link.download = 'bar_chart.png';
//       link.click();
//     });
// };




const handleDownload = () => {
  const svgNode = svgRef.current;

  // Create a canvas element to render the SVG content
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const svgString = new XMLSerializer().serializeToString(svgNode);

  // Set canvas background color to white
  canvas.width = svgNode.clientWidth;
  canvas.height = svgNode.clientHeight;
  ctx.fillStyle = '#ffffff'; // white color
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Create an image from the SVG content
  const img = new Image();
  img.onload = () => {
    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0);

    // Convert the canvas to a data URL
    const imageURL = canvas.toDataURL();
    // Trigger download
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'Sentiment_By_Month.png';
    link.click();
  };
  img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
};


const margin = { top: 0, right: 0, bottom: 40, left: 50 };
const width = Math.round(hw.width/3.3) - margin.left - margin.right;
const height = hw.width<1400?Math.round(hw.width/6)- margin.top - margin.bottom :Math.round(hw.width/6.3) - margin.top - margin.bottom;

  useEffect(() => {



    const svg = d3.select(svgRef.current);
    // Check if SVG already exists
    if (!svg.select("svg").empty()) {
      svg.select("svg").remove();
    }

    const svgContainer = svg
      .append("svg")
      .attr("width", width + margin.left + margin.right + 30)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    if (data.length === 0) return;

    
    const subgroups = data[data.length - 1].columns.slice(1);
    const groups = Array.from(new Set(data.map((d) => d.group)));

    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.1]);

    svgContainer
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickSize(0))
      .append("text")
      .attr("x", width / 2)
      .attr("y", 40)
      .attr("text-anchor", "middle")
      .text("Groups");

    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([subgroups.length<3 ? 0.3:0.1]);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) => d3.sum(subgroups, (key) => +d[key]))-subgroups.length>3 ? highestValue:20,
      ])
      .nice()
      .range([height, 30]);

    svgContainer.append("g").call(d3.axisLeft(y));
  
    const color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#2db300","#ff3300","rgba(250, 192, 19, 0.8)"]);

      // svgContainer
      // .selectAll(".bar-label")
      // .data(data)
      // .enter()
      // .append("text")
      // .attr("class", "bar-label")
      // .attr("x", (d) => {
      //   if (parseInt(d.Value) < 1000) {
      //     return x(d.Value) + 10; // Position outside the bar
      //   } else {
      //     return x(d.Value) - 50; // Position inside the bar
      //   }
      // })
      // .attr("y", (d) => y(d.Topic) + y.bandwidth() / 2)
      // .text((d) => d.Value)
      // .attr("dy", ".25em")
      // .attr("fill", (d) => {
      //   if (parseInt(d.Value) < 1000) {
      //     return color(d.Topic); // Bar color for smaller values
      //   } else {
      //     return "white"; // White color for labels inside bars
      //   }
      // })
      // .style("font-size", hw.width<1400?"15px":"18px");



    svgContainer
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => `translate(${x(d.group)},0)`)
      .selectAll("rect")
      .data((d) =>
        subgroups.map((key) => ({ key, value: +d[key], group: d.group }))
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xSubgroup(d.key))
      .attr("y", (d) => y(d.value))
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => height - y(d.value))
      .attr("fill", (d) => color(d.key));

    const legend = svgContainer
      .append("g")
      .attr("transform", `translate(${width - margin.right},${margin.top})`);
console.log("hw.width bar > ",hw.width);
      subgroups.forEach((key, i) => {
      const label = legend
        .append("g")
        .attr("transform", `translate(0, ${i * 16})`);
      label
        .append("rect")
        .attr("x", hw.width<1400?"-9.2%":hw.width>1700?"-9.5%":"-8%")
        .attr("y", hw.width<1400?"49.6%":hw.width>1700?"60%":"60%")//58
        .attr("width", hw.width<1400?10:hw.width>1700?12:12)
        .attr("height", hw.width<1400?10:hw.width>1700?12:12)
        .attr("fill", (d) => {
          if (key === "Positive") return "#2db300";
          if (key === "Neutral") return "rgba(250, 192, 19, 0.8)";
          if (key === "Negative") return "#ff3300";
        });
        // const colors = ["#2db300","rgba(250, 192, 19, 0.8)",  "#ff3300"];
      label
        .append("text")
        .attr("x", hw.width<1400?"-6%":hw.width>1700?"-7%":"-5%")
        .attr("y", hw.width<1400?"52%":hw.width>1700?"62%":"62.5%")//65
        .attr("dy", "0.35em")
        .style("font-size", hw.width<1400?"12px":hw.width>1700?"16px":"14px")
        .text(key.charAt(0).toUpperCase() + key.slice(1));
    });
  }, [data,hw.width]);

  // return (
  //   <div style={{display:"flex"}}>
  //     <div ref={svgRef}></div>
  //     <div>

  //     <button className="btn btn-primary btn-sm" style={{marginLeft:"20%",marginTop:"70%"}} onClick={handleDownload}>Download</button>
  //     <b>
  //       <h2 style={{ paddingLeft: "20px" ,marginTop:"20px"}}>
  //         Sentiment By Week
  //       </h2>
  //     </b>
  //     </div>
  //   </div>
  // );



  return (
    <div>
           
    <div
      style={backgroundStyle}
      onMouseEnter={() => {
        setShowDownloadButton(true)
        setBackgroundStyle({position: 'relative',backgroundColor:"#f0efef" })
      }}
      onMouseLeave={() => {
        setShowDownloadButton(false)
        setBackgroundStyle({position: 'relative',radius:"50px"})
      }}

      >

    <svg
      width={width + margin.left + margin.right+30} // Set width and height here
      height={height + margin.top + margin.bottom}
      ref={svgRef}
      style={backgroundStyle}
      // viewBox={`0 0 ${Math.round(hw.width/6)} ${Math.round(hw.width/6)}`} // Set viewBox to match dimensions
    >
      <g
        transform={`translate(${hw.width / 12},${hw.width / 12})`}
      >
      </g>
    </svg>


      {/* Button to trigger download */}
      {showDownloadButton && (
        <button
          className="btn"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            backgroundColor:"#626252"
          }}
          onClick={handleDownload}
        >
         <MdOutlineFileDownload style={{color:"white"}}/>
        </button>
      )}
    </div>
    </div>
  );

};

export default BarChart;

